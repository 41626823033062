<template>
  <div>
    <vs-card v-if="orders.length == 0">
      <div slot="header">
        <p>Não há requisições pendentes.</p>
      </div>
    </vs-card>

    <vs-card :key="index" v-for="(order, index) in orders">
      <div slot="header">
        <h3 :style="{ color: order.classification.color }">
          Requisição {{ order.code }} - [{{ order.classification.name }}]
        </h3>
      </div>

      <div class="card">
        <h4 v-if="order.inova">Desenvolvimento</h4>
        <h4 v-else>Requisição</h4>
        <feather-icon
          icon="MessageCircleIcon"
          class="cursor-pointer bt"
          @click="showComments(order.uuid)"
        />
        <feather-icon
          v-if="order.project.image"
          icon="CameraIcon"
          class="cursor-pointer"
          @click="showScreenImage(order.project.image)"
        />

        <p>
          Solicitado em {{ order.created }} pelo usuário
          <strong>{{ order.createdUser.name }}</strong>
        </p>
        <p v-if="!order.inova && order.necessityAt">
          Data de necessidade: <strong>{{ order.necessityDate }}</strong>
        </p>
        <p v-if="order.inova">
          Descrição Técnica: <strong>{{ order.project.description }}</strong>
        </p>
        <p v-if="order.duplicated" class="duplicated">
          <strong>*** Requisição duplicada! ***</strong>
        </p>
        <p v-if="order.justification">
          Justificativa da demanda: <strong>{{ order.justification }}</strong>
        </p>
        <p>
          Código: <strong>{{ order.project.code }}</strong>
        </p>
        <p>
          <strong>{{ order.project.name }} - {{ order.qtd }} unidade(s)</strong>
        </p>
      </div>

      <vs-button @click="openPopUp(order)" class="bt">Pré Aprovar</vs-button>
      <vs-button @click="openPopUp2(order)" class="bt">Reprovar</vs-button>
      <vs-button @click="openPrompt(order)" class="bt"
        >Adicionar comentário</vs-button
      >
      <vs-button @click="cancel(order)" v-if="hasPermissionToCancel"
        >Cancelar</vs-button
      >
    </vs-card>

    <vs-popup
      :active.sync="showPopUp"
      title="Selecionar Projetista"
      v-if="orderSelected"
    >
      Projetista
      <select
        name="designerId"
        v-validate="'required'"
        v-model="designerId"
        label="Projetista"
        class="w-full mb-6 select1"
        data-vv-as="Projetista"
      >
        <option
          :key="index"
          :value="item.id"
          v-for="(item, index) in userOptions"
        >
          {{ item.name }}
        </option>
      </select>

      <div v-if="orderSelected.inova">
        Data de previsão de entrega:
        <br />
        <vs-input
          type="date"
          v-validate="'required'"
          name="deliveryAt"
          class="w-full mb-6"
          v-model="deliveryAt"
          data-vv-as="Data de previsão"
        ></vs-input>
      </div>
      <vs-button @click="aprove()" class="bt">Confirmar</vs-button>
    </vs-popup>

    <vs-popup :active.sync="showPopUp2" title="Informações adicionais">
      Observação
      <vs-textarea
        v-model="description"
        placeholder="Digite observação sobre a reprovação"
      >
      </vs-textarea>

      <vs-button @click="reprove()" class="bt">Confirmar</vs-button>
    </vs-popup>

    <vs-popup :active.sync="showScreenComments" title="Comentários">
      <p v-if="comments.length == 0">Não há comentários cadastrados</p>
      <div :key="index" v-for="(comment, index) in comments">
        <p>
          <strong>{{ comment.description }}</strong>
        </p>
        <p>
          <small
            >Postado por {{ comment.user.name }} em {{ comment.created }}</small
          >
        </p>
        <vs-divider></vs-divider>
      </div>
    </vs-popup>

    <vs-prompt
      @accept="addComment"
      @cancel="comment = ''"
      @close="comment = ''"
      :active.sync="activePrompt"
      title="Comentário"
      acceptText="Postar"
      cancelText="Cancelar"
    >
      <vs-textarea
        placeholder="Digite seu comentário aqui"
        v-model="comment"
        class="w-full mb-6"
      ></vs-textarea>
    </vs-prompt>
    <vs-popup :active.sync="showImage" title="Imagem do Projeto" fullscreen>
      <img :src="image" title="Imagem do Projeto" />
    </vs-popup>
  </div>
</template>

    <script>
import siriusAPI from "../services";
import {
  notifyError,
  formatDateToLocale,
  notifySuccess,
} from "@/utils/helpers";
import moment from "moment";
export default {
  data() {
    return {
      orders: [],
      hasPermissionToCancel: false,
      userOptions: null,
      loggedUser: null,
      showPopUp: false,
      showPopUp2: false,
      designerId: null,
      deliveryAt: null,
      description: null,
      comment: null,
      activePrompt: false,
      showScreenComments: false,
      comments: [],
      orderSelected: null,
      showImage: false,
      image: null,
    };
  },

  created() {
    this.loadOrders();
    this.loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    const userProfile = JSON.parse(localStorage.getItem("userInfo")).profile
      .name;
    const profiles = ["Administrador"];
    this.hasPermissionToCancel = profiles.indexOf(userProfile);
  },

  methods: {
    showScreenImage(image) {
      this.showImage = true;
      this.image = process.env.VUE_APP_URL_IMAGES + image;
    },
    validateMinDate(deliveryAt) {
      deliveryAt = moment(deliveryAt);
      let today = moment();
      let dif = deliveryAt.diff(today, "days");
      if (dif < 15) return false;
      return true;
    },
    showComments(uuid) {
      this.comments = [];
      this.$vs.loading();
      new siriusAPI()
        .getComments(uuid)
        .then((res) => {
          this.comments = res.data;
          this.comments = this.comments.map((comment) => ({
            ...comment,
            created: formatDateToLocale(comment.createdAt),
            updated: formatDateToLocale(comment.updatedAt),
          }));
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de comentários: " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
      this.showScreenComments = true;
    },

    loadUsers() {
      this.$vs.loading();
      new siriusAPI()
        .getUsersWithParams(null, this.loggedUser.unit.id, 3)
        .then((res) => {
          this.userOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de usuários " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    loadOrders() {
      this.$vs.loading();
      new siriusAPI()
        .getPendingOrders()
        .then((res) => {
          this.orders = res.data;
          this.orders = this.orders.map((order) => ({
            ...order,
            created: formatDateToLocale(order.createdAt),
            necessityDate: formatDateToLocale(order.necessityAt),
          }));
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de Requisições " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    openPopUp(order) {
      this.deliveryAt = moment().add("30", "days").format("YYYY-MM-DD");
      this.loadUsers();
      this.orderSelected = order;
      this.showPopUp = true;
    },
    openPopUp2(order) {
      this.orderSelected = order;
      this.showPopUp2 = true;
    },
    openPrompt(order) {
      this.orderSelected = order;
      this.activePrompt = true;
    },
    addComment() {
      const data = {
        description: this.comment,
      };
      this.$vs.loading();
      new siriusAPI()
        .addComment(this.orderSelected.uuid, data)
        .then(() => {
          notifySuccess(this, "Comentário adicionado com sucesso");
          this.$vs.loading.close();
          this.comment = "";
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar inserir um comentário: " + error);
        });
    },

    aprove() {
      if (!this.designerId) {
        notifyError(
          this,
          "Obrigatório selecionar o projetista para aprovar a requisição!"
        );
        return;
      }
      if (!this.validateMinDate(this.deliveryAt)) {
        notifyError(this, "O prazo mínimo é de 15 dias!");
        return;
      }
      const data = {
        phaseId: 14,
        designerId: this.designerId,
        deliveryAt: this.deliveryAt,
      }; /*pre-APROVADO */
      this.$vs.loading();

      new siriusAPI()
        .updatePhase(this.orderSelected.uuid, data)
        .then(() => {
          notifySuccess(this, "Requisição aprovado com sucesso");
          this.$vs.loading.close();
          this.loadOrders();
          this.showPopUp = false;
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar aprovar a requisição: " + error);
        });
    },

    async reprove() {
      const data = {
        phaseId: 12,
        description: this.description,
      }; /*REPROVADO */
      this.$vs.loading();

      await new siriusAPI()
        .updatePhase(this.orderSelected.uuid, data)
        .then(() => {
          new siriusAPI().addComment(this.orderSelected.uuid, data);

          notifySuccess(this, "Requisição reprovada com sucesso");
          this.$vs.loading.close();
          this.showPopUp2 = false;
          this.loadOrders();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar reprovar a requisição: " + error);
        });
    },

    cancel(order) {
      const data = { phaseId: 10 }; /*cancelar */
      this.$vs.loading();

      new siriusAPI()
        .updatePhase(order.uuid, data)
        .then(() => {
          notifySuccess(this, "Requisição cancelada com sucesso");
          this.$vs.loading.close();
          this.loadOrders();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar cancelar a requisição: " + error);
        });
    },
  },
};
</script>
<style scoped>
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.card {
  margin-bottom: 3em;
}
.border {
  border: 1px dotted #6666;
}
.bt {
  margin-right: 1em;
}
.duplicated {
  color: red;
}
</style>
